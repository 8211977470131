

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import {ChartValue} from "@/models/chartValue";
import takeColor from "@/utils/color";
import {ThongKe} from "@/models/thongKe";
import QLTSRepository from "@/services/QLTSRepository";
import {TongHop} from "@/models/tongHop";

export default {

  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listThongKeTiepNhan = ref([] as ThongKe[]);
    const listThongKeTiepNhanTrongNam = ref([] as ThongKe[]);
    const listThongKeHetHanTrongNam = ref([] as ThongKe[]);
    const listChart = ref([] as ChartValue[]);
    const listChartBS = ref([] as ChartValue[]);
    const tongHop = ref([] as TongHop[]);
    const listThongKeKhoaTrongNam= ref([] as ThongKe[]);
    const listThongKeHienCo= ref([] as ThongKe[]);

    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }
    console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission @@$$$$$$$$$$$ getListSoLuongTiepNhanTrong12Thang: ");
    QLTSRepository.getListThongKeTheoKhoaTrong12Thang()
        .then((response) => {
          listThongKeKhoaTrongNam.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    QLTSRepository.getListThongKeThietBiHienCo()
        .then((response) => {
          listThongKeHienCo.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    QLTSRepository.getListSoLuongTiepNhanTrong12Thang()
        .then((response) => {
          listThongKeTiepNhanTrongNam.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    QLTSRepository.getListSoLuongQuaHanTrong12Thang()
        .then((response) => {
          listThongKeHetHanTrongNam.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    QLTSRepository.getListThongKeTrong12Thang()
        .then((response) => {
          tongHop.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });


    const chartOptions = computed(() => {
      const data = listThongKeTiepNhanTrongNam.value;
      return {
        chart: {
          type: 'bar',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          foreColor: '#373d3f',
          toolbar: {
            show: false
          },
        },
        tooltip: {
          show: true,
          style: {
            fontSize: '12px',
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          background: 'lightblue',
          color: 'darkblue',
          y: {
            formatter: function (val) {
              return val + " thiết bị";
            }
          }
        },
        title: {
          text: "Thống kê thiết bị tiếp nhận trong 12 tháng",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        colors: ['#05b2fc','#f6235a'],
        // fillSeriesColor: true,
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: data.map(x => x.ten),
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    });

    const series = computed(() => {
      const data = listThongKeTiepNhanTrongNam.value;
      const data2 = listThongKeHetHanTrongNam.value;
      return [
        {
          name: "Tiếp nhận",
          data: data.map(x => x.soLuong)
        },
        {
          name: "Hết hạn",
          data: data2.map(x => x.soLuong)
        }
      ]
    });

    const chartOptionsHetHan = computed(() => {
      const data = listThongKeHetHanTrongNam.value;
      return {
        chart: {
          type: 'bar',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          foreColor: '#33FFCC',
          toolbar: {
            show: false
          },
        },
        tooltip: {
          show: true,
          style: {
            fontSize: '12px',
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          background: 'lightblue',
          color: 'darkblue',
          y: {
            formatter: function (val) {
              return val + " thiết bị";
            }
          }
        },
        title: {
          text: "Thống kê thiết bị hết hạn trong 12 tháng",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        colors: ['#f6235a'],
        // fillSeriesColor: true,
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: data.map(x => x.ten),
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    });

    const seriesHetHan = computed(() => {
      const data = listThongKeHetHanTrongNam.value;
      return [
        {
          name: "Số lượng",
          data: data.map(x => x.soLuong)
        }
      ]
    });



    const listColor = ref([] as string[]);
    listColor.value = takeColor(30);
    ////

    ////

    const optionsYear = computed(() => {
      const data = tongHop.value;
      return {
        chart: {
          type: 'area',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.1
          },
          toolbar: {
            show: false
          },
          rotate: 0
        },
        title: {
          text: ".",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        colors: ['#008FFB', '#fd3d70', '#CED4DC'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '10px',
          }
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.8
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: data.map(x => x.ten),
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    })

    const seriesYear = computed(() => {
      const data = tongHop.value;
      return [{
        name: 'Số tb tiếp nhận',
        data: data.map(x => x.soLuongTiepNhan)
      },
        {
          name: 'Số tb hết hạn',
          data: data.map(x => x.soLuongHetHan)
        },
        {
          name: 'Số tb thanh lý',
          data: data.map(x => x.soLuongThanhLy)
        },
      ]
    });

    const chartDepartmentsOptions = computed(() => {
      const data = listThongKeKhoaTrongNam.value;
      console.log("&&^^^^^^^^^^^^^^^^^ chartAgeOptions data: " + JSON.stringify(data));
      return{
        chart: {
          height: '100%',
          width: '100%',
          type: 'donut',
          toolbar: {
            show: false
          },
          dropShadow: {
            enabled: true,
            color: '#706f6f',
            top: -1,
            left: 1,
            blur: 1,
            opacity: 0.1
          }
        },
        colors: listColor.value,
        title: {
          text: "Thống kê số lượt tiếp nhận thiết bị trong 12 tháng",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        },
        selection: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          offset: 0,
          minAngleToShowLabel: 10,
        },
        tooltip: {
          theme: false,
          y: {
            formatter: function (val) {
              return val + " tiếp nhận";
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        fill: {
          type: 'gradient',
        },
        labels: data.map(x => x.ten),
      }
    })

    const departmentsSeries = computed(() => {
      const data = listThongKeKhoaTrongNam.value;
      return data.map(x => x.soLuong)
    })

    const chartAllOptions = computed(() => {
      const data = listThongKeHienCo.value;
      console.log("&&^^^^^^^^^^^^^^^^^ chartAgeOptions data: " + JSON.stringify(data));
      return{
        chart: {
          height: '100%',
          width: '100%',
          type: 'pie',
          toolbar: {
            show: false
          },
          dropShadow: {
            enabled: true,
            color: '#706f6f',
            top: -1,
            left: 1,
            blur: 1,
            opacity: 0.1
          }
        },
        colors: takeColor(60),
        title: {
          text: "Thống kê tổng số thiết bị đang hoạt động của từng khoa phòng",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        },
        selection: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          offset: 0,
          minAngleToShowLabel: 10,
        },
        tooltip: {
          theme: false,
          y: {
            formatter: function (val) {
              return val + " thiết bị";
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        fill: {
          type: 'gradient',
        },
        labels: data.map(x => x.ten),
      }
    })

    const AllSeries = computed(() => {
      const data = listThongKeHienCo.value;
      return data.map(x => x.soLuong)
    })

    return {
      chartOptions,
      series,
      chartOptionsHetHan,
      seriesHetHan,
      optionsYear,
      seriesYear,
      departmentsSeries,
      chartDepartmentsOptions,
      chartAllOptions,
      AllSeries
    }
  }
}

