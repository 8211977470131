
import {computed, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {UserDetail} from "@/models/userDetail";
import {KhoaPhong} from "@/models/khoaPhong";
import QLTSRepository from "@/services/QLTSRepository";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as UserDetail[]);
    const store = useStore();

    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }

    const khoaPhong = ref([] as KhoaPhong[]);

    const userPermission = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Nhân viên", value: "user", param: 2},
    ]);

    const loadKhoaPhong = () => {
      QLTSRepository.getListKhoaPhong()
          .then((response) => {
            khoaPhong.value = response.data;
            AuthRepository.getListUser()
                .then((response) => {
                  list.value = response.data;
                  list.value.forEach(x => {
                    khoaPhong.value.forEach(y => {
                      if(y.id == x.idKhoaPhong)
                      {
                        x.khoaPhong = y.tenKhoaPhong
                      }
                    });
                    userPermission.value.forEach(y => {
                      if(x.permission == y.value){
                        x.permission = y.label;
                      }
                    })
                  })
                })
                .catch();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    loadKhoaPhong();

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const activeStatus = (i) => {
      if(i)
      {
        return "Đang hoạt động";
      }
      else
        return "Đang tạm ngưng"
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      activeStatus
    }
  }
}
