

import { computed, ref } from 'vue'
import moment from 'moment';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ThietBi} from "@/models/thietBi";
import QLTSRepository from "@/services/QLTSRepository";
import {FilterMatchMode} from "primevue/api";
import {LichSuBanGiao} from "@/models/lichSuBanGiao";
import {KhoaPhong} from "@/models/khoaPhong";
import {ThietBiNgungHoatDong} from "@/models/thietBiNgungHoatDong";
import axios from "axios";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listThietBiTiepNhan = ref([] as ThietBi[]);
    const listThietBiChuyenGiao = ref([] as ThietBi[]);
    const listThietBiHetHan = ref([] as ThietBi[]);
    const listThietBiHoatDong = ref([] as ThietBi[]);
    const listThietBiTheoKhoa = ref([] as ThietBi[]);
    const lichSu = ref([] as LichSuBanGiao[]);
    const chiTietThietBi = ref(false);
    const chuyen = ref(false);
    const ngung = ref(false);
    const choThanhLy = ref(false);
    const khoaPhong = ref([] as KhoaPhong[]);
    const thietBiChuyen = ref({} as ThietBi);
    const thietBiNgung = ref({} as ThietBi);
    const thietBiNgungHoatDong = ref({} as ThietBiNgungHoatDong);
    const listThietBiNgungHoatDong = ref([] as ThietBi[]);
    const thietBiCho = ref({} as ThietBi);
    const thietBiChoThanhLy = ref({} as ThietBiNgungHoatDong);
    const listThietBiChoThanhLy = ref([] as ThietBi[]);

    const loadKhoaPhong = () => {
      QLTSRepository.getListKhoaPhong()
          .then((response) => {
            khoaPhong.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const waitingForLiquidationThietBi = () => {
      QLTSRepository.waitingForLiquidationThietBi(thietBiChoThanhLy.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Chuyển chờ thanh lý thiết bị thành công',
              life: 2000
            });
            loadThietBiTiepNhan();
            loadThietBiChuyenGiao();
            getListThietBiHetHan();
            loadThietBiHoatDong();
            getListThietBiNgungHoatDong();
            getListThietBiChoThanhLy();
            choThanhLy.value = false;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const deactiveThietBi = () => {
      QLTSRepository.deactiveThietBi(thietBiNgungHoatDong.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Ngưng thiết bị thành công',
              life: 2000
            });
            loadThietBiTiepNhan();
            loadThietBiChuyenGiao();
            getListThietBiHetHan();
            loadThietBiHoatDong();
            getListThietBiNgungHoatDong();
            getListThietBiChoThanhLy();
            ngung.value = false;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    loadKhoaPhong()

    const idThietBiChuyen = ref(0);
    const tenThietBi = ref("");

    const chuyenThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% chuyenThietBi: " + id);
      listThietBiTiepNhan.value.forEach(x => {
        if(x.id == id){
          tenThietBi.value = x.tenThietBi;
          idThietBiChuyen.value = x.id;
        }
      });
      chuyen.value = true;
    }


    const loadLichSuBanGiao = (id) => {
        QLTSRepository.getListLichSuBanGiaoById(id)
                .then((response) => {
                  lichSu.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const dateNgung= ref();
    const getListThietBiNgungHoatDong = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateNgung.value != null)
      {
        time1.value = dateNgung.value[0]/1000;
        time2.value = dateNgung.value[1]/1000;
      }
        QLTSRepository.getListThietBiNgungHoatDong(time1.value, time2.value)
                .then((response) => {
                  listThietBiNgungHoatDong.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const dateChoThanhLy= ref();
    const downloadFileChoThanhLy = ref(false);
    const getListThietBiChoThanhLy = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateChoThanhLy.value != null)
      {
        time1.value = dateChoThanhLy.value[0]/1000;
        time2.value = dateChoThanhLy.value[1]/1000;
      }
      QLTSRepository.getListThietBiChoThanhLy(time1.value, time2.value)
          .then((response) => {
          listThietBiChoThanhLy.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const dateHetHan = ref();
    const getListThietBiHetHan = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateHetHan.value != null)
      {
        time1.value = dateHetHan.value[0]/1000;
        time2.value = dateHetHan.value[1]/1000;
      }
        QLTSRepository.getListThietBiHetHan(time1.value, time2.value)
                .then((response) => {
                  listThietBiHetHan.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const loadThietBiHoatDong = () => {
        QLTSRepository.getListThietBiHoatDong()
                .then((response) => {
                  listThietBiHoatDong.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const loadChiTiet = (id) => {
      chiTietThietBi.value = true;

      loadLichSuBanGiao(id);
    }

    const dateAll = ref();
    const loadThietBiTiepNhan = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateAll.value != null)
      {
        time1.value = dateAll.value[0]/1000;
        time2.value = dateAll.value[1]/1000;
      }
        QLTSRepository.getListThietBiAll(time1.value, time2.value)
                .then((response) => {
                  listThietBiTiepNhan.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const dateChuyenGiao = ref();
    const loadThietBiChuyenGiao = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateChuyenGiao.value != null)
      {
        time1.value = dateChuyenGiao.value[0]/1000;
        time2.value = dateChuyenGiao.value[1]/1000;
      }
      QLTSRepository.getListThietBiChuyenGiao(time1.value, time2.value)
                .then((response) => {
                  listThietBiChuyenGiao.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const dateKhoaPhong= ref();
    const idKhoa = ref(0);
    const downloadFileKhoaPhong = ref(false);
    const getListThietBiTheoKhoa = () => {
      downloadFileKhoaPhong.value = false;
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateKhoaPhong.value != null)
      {
        time1.value = dateKhoaPhong.value[0]/1000;
        time2.value = dateKhoaPhong.value[1]/1000;
      }
      QLTSRepository.getListThietBiTheoKhoa(idKhoa.value, time1.value, time2.value)
          .then((response) => {
            listThietBiTheoKhoa.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    loadThietBiTiepNhan();
    loadThietBiChuyenGiao();
    getListThietBiHetHan();
    loadThietBiHoatDong();
    getListThietBiNgungHoatDong();
    getListThietBiTheoKhoa();
    getListThietBiChoThanhLy();

    const confirm = (id: number) => {
      QLTSRepository.confirmThietBi(id)
          .then((response) => {
            loadThietBiTiepNhan();
            loadThietBiChuyenGiao();
            loadThietBiHoatDong();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const thucHienChuyenThietBi = () => {
      QLTSRepository.tranferThietBi(idThietBiChuyen.value, thietBiChuyen.value.idKhoaPhongTiepNhan)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm thiết bị thành công',
              life: 2000
            });
            chuyen.value = false;
            loadThietBiTiepNhan();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('DD/MM/YYYY');
    };

    const moneyValue = (x) => {
      if(x == null){
        return 0;
      }
      else
      return Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(x);
    };
    const expandedRowGroups = ref();

    const calculateGroupTotal = (id) => {
      let total = 0;

      if (listThietBiTiepNhan.value) {
        for (let thietBi of listThietBiTiepNhan.value) {
          if (thietBi.idLoaiThietBi === id) {
            total++;
          }
        }
      }

      return total;
    };
    const xoaThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% xoaThietBi: " + id);
      listThietBiChoThanhLy.value.forEach(x => {
        if(x.id == id){
          thietBiNgung.value.tenThietBi = x.tenThietBi;
          thietBiNgungHoatDong.value.id = x.id;
        }
      });
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% xoaThietBi thietBiNgung.value.tenThietBi: " + thietBiNgung.value.tenThietBi);
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% xoaThietBi thietBiNgungHoatDong.value.id: " + thietBiNgungHoatDong.value.id);
      ngung.value = true;
    }

    const choThanhLyThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% xoaThietBi: " + id);
      listThietBiTiepNhan.value.forEach(x => {
        if(x.id == id){
          thietBiCho.value.tenThietBi = x.tenThietBi;
          thietBiChoThanhLy.value.id = x.id;
        }
      });
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% xoaThietBi thietBiNgung.value.tenThietBi: " + thietBiCho.value.tenThietBi);
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% xoaThietBi thietBiNgungHoatDong.value.id: " + thietBiChoThanhLy.value.id);
      choThanhLy.value = true;
    }

    const valid = computed(()=> thietBiChuyen.value.idKhoaPhongTiepNhan);
    const validNgung = computed(()=> thietBiNgungHoatDong.value.lyDoNgung);
    const validCho = computed(()=> thietBiChoThanhLy.value.lyDoNgung);

    const downloadFile = ref(false);
    const downloadFileNgung = ref(false);

    const downloadFileHetHan = ref(false);

    const loadingBar = ref(false);
    const fileName = ref("");
    const fileNameNgung = ref("");
    const fileNameChoThanhLy = ref("");
    const fileNameHetHan = ref("");
    const fileNameKhoaPhong = ref("");
    const loadingExport = ref(false);
    const exportFile = (list: ThietBi[], id: number) => {
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% exportFile list: " + JSON.stringify(list));
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% exportFile id: " + id);
      loadingExport.value = true;
      downloadFile.value = false;
      downloadFileNgung.value = false;
      downloadFileHetHan.value = false;
      downloadFileChoThanhLy.value = false;
      downloadFileKhoaPhong.value = false;
      QLTSRepository.exportBaoCao(list, id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Xuất dữ liệu',
              detail: 'Xuất dữ liệu thành công',
              life: 2000
            });
            loadingExport.value = false;
            if(id == 1) {
              fileName.value = response.data;
              downloadFile.value = true;
            }
            else if(id == 3)
            {
              fileNameNgung.value = response.data;
              downloadFileNgung.value = true;
            }
            else if(id == 2)
            {
              fileNameHetHan.value = response.data;
              downloadFileHetHan.value = true;
            }
            else if(id == 5)
            {
              fileNameKhoaPhong.value = response.data;
              downloadFileKhoaPhong.value = true;
            }
            else if(id == 6)
            {
              fileNameChoThanhLy.value = response.data;
              downloadFileChoThanhLy.value = true;
            }

            // router.push({
            //   name: 'home'
            // });
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const downLoadFileExport = (file) => {
      axios({
        url: '../api/quanlytaisan/downloadListChart/' + file,
        method: 'GET',
        responseType: 'blob',
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "DanhSachThongKe" + ".xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    };

    return {
      error,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      moneyValue,
      listThietBiTiepNhan,
      listThietBiChuyenGiao,
      confirm,
      loadLichSuBanGiao,
      loadChiTiet,
      chiTietThietBi,
      lichSu,
      listThietBiHetHan,
      expandedRowGroups,
      calculateGroupTotal,
      chuyenThietBi,
      chuyen,
      tenThietBi,
      thietBiChuyen,
      khoaPhong,
      thucHienChuyenThietBi,
      valid,
      listThietBiHoatDong,
      deactiveThietBi,
      thietBiNgungHoatDong,
      ngung,
      thietBiNgung,
      validNgung,
      xoaThietBi,
      listThietBiNgungHoatDong,
      dateAll,
      loadThietBiTiepNhan,
      dateHetHan,
      getListThietBiHetHan,
      exportFile,
      downLoadFileExport,
      downloadFile,
      loadingBar,
      fileName,
      loadingExport,
      downloadFileNgung,
      fileNameNgung,
      downloadFileHetHan,
      fileNameHetHan,
      downloadFileKhoaPhong,
      fileNameKhoaPhong,
      getListThietBiNgungHoatDong,
      dateNgung,
      getListThietBiTheoKhoa,
      dateKhoaPhong,
      idKhoa,
      listThietBiTheoKhoa,
      waitingForLiquidationThietBi,
      choThanhLy,
      choThanhLyThietBi,
      listThietBiChoThanhLy,
      dateChoThanhLy,
      getListThietBiChoThanhLy,
      fileNameChoThanhLy,
      downloadFileChoThanhLy,
      thietBiCho,
      thietBiChoThanhLy,
      validCho,
    }
  }
}

