

import { computed, ref } from 'vue'
import moment from 'moment';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import check130Repository from "@/services/Check130Repository";
import {FilterMatchMode} from "primevue/api"
import {Dulieu130} from "@/models/dulieu130";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listLoi130  = ref([] as Dulieu130[]);
    const maBenhAn = ref("");
    const loadingBar = ref(false);

    const loadLoiDuLieu130 = () => {
      loadingBar.value = true;
      check130Repository.getListLoi130(maBenhAn.value)
          .then((response) => {
            listLoi130.value = response.data;
            loadingBar.value = false;
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('HH:mm DD/MM/YYYY');
    };

    const valid = computed(()=> true);

    const expandedRowGroups = ref();


    return {
      error,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      loadingBar,
      loadLoiDuLieu130,
      listLoi130,
      maBenhAn,
      expandedRowGroups
    }
  }
}

