import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: '',
    permission: '',
    fullName: '',
    team: '',
  },
  getters: {
    isLoggedIn (state): boolean {
      return !!state.token
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    clearToken (state) {
      state.token = ''
    },
    setPermission (state, permisson) {
      state.permission = permisson
    },
    clearPermission (state) {
      state.permission = ''
    },
    setFullName (state, fullName) {
      state.fullName = fullName
    },
    clearFullName (state) {
      state.fullName = ''
    },
    setTeam (state, team) {
      state.team = team
    },
    clearTeam (state) {
      state.team = ''
    },
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    clearToken ({ commit }) {
      commit('clearToken')
    },
    setPermission ({ commit }, permission) {
      commit('setPermission', permission)
    },
    clearPermission ({ commit }) {
      commit('clearPermission')
    },
    setFullName ({ commit }, fullName) {
      commit('setFullName', fullName)
    },
    clearFullName ({ commit }) {
      commit('clearFullName')
    },
    setTeam ({ commit }, team) {
      commit('setTeam', team)
    },
    clearTeam ({ commit }) {
      commit('clearTeam')
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
