import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bieudo" }
const _hoisted_2 = { class: "main_bieudo" }
const _hoisted_3 = { class: "item_bieudo" }
const _hoisted_4 = { class: "partchart v-5" }
const _hoisted_5 = { class: "partchart v-5" }
const _hoisted_6 = { class: "item_bieudo" }
const _hoisted_7 = { class: "partchart v-5" }
const _hoisted_8 = { class: "partchart v-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmPopup),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_apexchart, {
              type: "pie",
              options: $setup.chartAllOptions,
              series: $setup.AllSeries
            }, null, 8, ["options", "series"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_apexchart, {
              type: "bar",
              options: $setup.chartOptions,
              series: $setup.series
            }, null, 8, ["options", "series"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_apexchart, {
              type: "area",
              options: $setup.optionsYear,
              series: $setup.seriesYear
            }, null, 8, ["options", "series"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_apexchart, {
              type: "donut",
              options: $setup.chartDepartmentsOptions,
              series: $setup.departmentsSeries,
              style: {"width":"100%"}
            }, null, 8, ["options", "series"])
          ])
        ])
      ])
    ])
  ], 64))
}