

import { computed, ref } from 'vue'
import moment from 'moment';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ThietBi} from "@/models/thietBi";
import QLTSRepository from "@/services/QLTSRepository";
import {FilterMatchMode} from "primevue/api";
import {LichSuBanGiao} from "@/models/lichSuBanGiao";
import {KhoaPhong} from "@/models/khoaPhong";
import {ThietBiReject} from "@/models/thietBiReject";
import {forEach} from "lodash";
import {BienBan} from "@/models/bienBan";
import axios from "axios";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listThietBiTiepNhan = ref([] as ThietBi[]);
    const listThietBiChuyenGiao = ref([] as ThietBi[]);
    const lichSu = ref([] as LichSuBanGiao[]);
    const chiTietThietBi = ref(false);
    const chuyen = ref(false);
    const khoaPhong = ref([] as KhoaPhong[]);
    const thietBiChuyen = ref({} as ThietBi);
    const thietBiReject = ref({} as ThietBiReject);
    const listThietBiTuChoi = ref([] as ThietBi[]);
    const linkDownload = ref("");

    const loadKhoaPhong = () => {
      QLTSRepository.getListKhoaPhong()
          .then((response) => {
            khoaPhong.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    loadKhoaPhong()

    const idThietBiChuyen = ref(0);
    const tenThietBi = ref("");

    const chuyenThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% chuyenThietBi: " + id);
      listThietBiTiepNhan.value.forEach(x => {
        if(x.id == id){
          tenThietBi.value = x.tenThietBi;
          idThietBiChuyen.value = x.id;
        }
      });
      chuyen.value = true;
    }

    const tuChoi = ref(false);
    const xuat = ref(false);

    const tuChoiThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% tu choi: " + id);
      listThietBiChuyenGiao.value.forEach(x => {
        if(x.id == id){
           thietBiReject.value.id = x.id;
        }
      });
      tuChoi.value = true;
    }

    const loadLichSuBanGiao = (id) => {
        QLTSRepository.getListLichSuBanGiaoById(id)
                .then((response) => {
                  lichSu.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const loadChiTiet = (id) => {
      chiTietThietBi.value = true;

      loadLichSuBanGiao(id);
    }

    const date = ref();
    const dateChuyenGiao = ref();
    const dateReject = ref();
    const loadThietBiTiepNhan = () => {
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% abcd: ");
      const time1 = ref(0);
      const time2 = ref(0);
      if(date.value != null)
      {
        time1.value = date.value[0]/1000;
        time2.value = date.value[1]/1000;
      }
        QLTSRepository.getListThietBiTiepNhan(time1.value, time2.value)
                .then((response) => {
                  listThietBiTiepNhan.value = response.data;
                  console.log("######$$$$$$$$$$%%%%%%%%%%%%%% listThietBiTiepNhan: " + JSON.stringify(listThietBiTiepNhan.value));
                  listThietBiTiepNhan.value.forEach(x => x.banGiao = false);
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const loadThietBiChuyenGiao = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateChuyenGiao.value != null)
      {
        time1.value = dateChuyenGiao.value[0]/1000;
        time2.value = dateChuyenGiao.value[1]/1000;
      }
        QLTSRepository.getListThietBiChuyenGiao(time1.value, time2.value)
                .then((response) => {
                  listThietBiChuyenGiao.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const loadThietBiTuChoi = () => {
      const time1 = ref(0);
      const time2 = ref(0);
      if(dateReject.value != null)
      {
        time1.value = dateReject.value[0]/1000;
        time2.value = dateReject.value[1]/1000;
      }
        QLTSRepository.getListThietBiReject(time1.value, time2.value)
                .then((response) => {
                  listThietBiTuChoi.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    loadThietBiTiepNhan();
    loadThietBiChuyenGiao();
    loadThietBiTuChoi();

    const confirm = (id: number) => {
      QLTSRepository.confirmThietBi(id)
          .then((response) => {
            loadThietBiTiepNhan();
            loadThietBiChuyenGiao();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const thucHienChuyenThietBi = () => {
      QLTSRepository.tranferThietBi(idThietBiChuyen.value, thietBiChuyen.value.idKhoaPhongTiepNhan)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm thiết bị thành công',
              life: 2000
            });
            chuyen.value = false;
            loadThietBiTiepNhan();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const thietBiRej = () => {
      QLTSRepository.rejectThietBi(thietBiReject.value)
          .then((response) => {
            tuChoi.value = false;
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Từ chối thiết bị thành công',
              life: 2000
            });
            loadThietBiChuyenGiao();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('DD/MM/YYYY');
    };

    const moneyValue = (x) => {
      if(x == null){
        return 0;
      }
      else
      return Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(x);
    };

    const valid = computed(()=> thietBiChuyen.value.idKhoaPhongTiepNhan);
    const validTuChoi = computed(()=> thietBiReject.value.lyDo);
    const validBienBan = computed(()=> bienBan1.value.benGiao1 && bienBan1.value.chucVuBenGiao1&& bienBan1.value.benGiao2&& bienBan1.value.chucVuBenGiao2&& bienBan1.value.benNhan1&& bienBan1.value.chucVuBenNhan1
        && bienBan1.value.benNhan2&& bienBan1.value.chucVuBenNhan2&& bienBan1.value.ghiChu);
    const trangThai = (i) => {
      if(i) return "Thiết bị đã từ chối";
      else return "Thiết bị đã nhận";
    }

    const expandedRowGroups = ref();
    const link = ref("");

    const bienBan1 = ref({} as BienBan);

    const exportTranferPage = () => {
      xuat.value = true;
      const listThietBi = ref([] as ThietBi[]);
      listThietBiTiepNhan.value.forEach(x => {
        if(x.banGiao) {
          listThietBi.value.push(x);
        }
      });
      bienBan1.value.listThietBi = listThietBi.value;
      console.log("#############***************** listThietBi:" + JSON.stringify(listThietBi.value));
      
    }

    const loadingBar = ref(false);
    const exportFile = () => {
      loadingBar.value = true;
      QLTSRepository.createBanGiao(bienBan1.value)
          .then((response) => {
            link.value = response.data;
            xuat.value = false;
            loadingBar.value = false;
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Xuất biên bản thành công',
              life: 2000
            });
          })
          .catch(err => {
            loadingBar.value = false;
            xuat.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const validExportPage = computed(() =>{
      const validTemp = ref(false);
      listThietBiTiepNhan.value.forEach(x => {
        if(x.banGiao){
          validTemp.value = true;
        }
      })
      return validTemp.value;
    })

    const downLoadFileExport = (file) => {
      // axios({
      //   url: '../api/quanlytaisan/downloadFile/' + file,
      //   method: 'GET',
      //   responseType: 'blob',
      // })
      //     .then((response) => {
      //       const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', "Bien_Ban_Ban_Giao" + ".pdf");
      //       document.body.appendChild(link);
      //       link.click();
      //     })
      //     .catch(err => {
      //       toast.add({
      //         severity: 'error',
      //         summary: 'Lỗi',
      //         detail:err.response.data,
      //         life: 2500
      //       })});
      const link = ref("");
      link.value = window.location.href.split(window.location.pathname)[0] + "/UploadFolder/" + file;
      window.open(link.value, '_blank');
    };

    return {
      error,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      moneyValue,
      listThietBiTiepNhan,
      listThietBiChuyenGiao,
      confirm,
      loadLichSuBanGiao,
      loadChiTiet,
      chiTietThietBi,
      lichSu,
      chuyenThietBi,
      chuyen,
      tenThietBi,
      thietBiChuyen,
      khoaPhong,
      thucHienChuyenThietBi,
      valid,
      thietBiReject,
      thietBiRej,
      tuChoi,
      tuChoiThietBi,
      validTuChoi,
      listThietBiTuChoi,
      trangThai,
      date,
      loadThietBiTiepNhan,
      dateChuyenGiao,
      dateReject,
      loadThietBiChuyenGiao,
      loadThietBiTuChoi,
      expandedRowGroups,
      exportTranferPage,
      validExportPage,
      bienBan1,
      xuat,
      exportFile,
      validBienBan,
      downLoadFileExport,
      link,
      loadingBar
    }
  }
}

