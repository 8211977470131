import Repository from './Repository';
import {AxiosResponse} from "axios";

const resource = '/benhnhan';

export default new class{
    getListLoi130(st: string): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/duLieu130/${st}`);
    }
}
