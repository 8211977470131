

import { computed, ref } from 'vue'
import moment from 'moment';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ThietBi} from "@/models/thietBi";
import QLTSRepository from "@/services/QLTSRepository";
import {FilterMatchMode} from "primevue/api";
import {LichSuBanGiao} from "@/models/lichSuBanGiao";
import {KhoaPhong} from "@/models/khoaPhong";
import {ThietBiReject} from "@/models/thietBiReject";
import {forEach} from "lodash";
import {BienBan} from "@/models/bienBan";
import axios from "axios";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const giaTri = ref(0);
    const loai = ref(0);
    const file = ref("");
    const loadingBar = ref(false);
    const valid = computed(()=> giaTri.value && loai.value > 0);

    const kind = ref([
      {label: "Báo cáo lớn hơn" , param: 1},
      {label: "Báo cáo nhỏ hơn" , param: 2},
    ]);

    const createBaoCaoTheoGiaTri = () => {
      loadingBar.value = true;
      file.value = "";
        QLTSRepository.createBaoCaoTheoGiaTri(giaTri.value, loai.value)
                .then((response) => {
                  file.value = response.data;
                  loadingBar.value = false;
                })
                .catch(err => {
                  loadingBar.value = false;
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };



    const expandedRowGroups = ref();


    const downLoadFileExport = (file) => {
      axios({
        url: '../api/quanlytaisan/downloadListChart/' + file,
        method: 'GET',
        responseType: 'blob',
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "DanhSachThongKe" + ".xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    };

    return {
      error,
      filters,
      clearFilter,
      initFilters,
      confirm,
      downLoadFileExport,
      file,
      loadingBar,
      giaTri,
      loai,
      kind,
      valid,
      createBaoCaoTheoGiaTri
    }
  }
}

