
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";
import {ThietBi} from "@/models/thietBi";
import QLTSRepository from "@/services/QLTSRepository";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const listThietBiChuyenGiao = ref([] as ThietBi[]);
    const defaultMenu = [
    ];
    console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission topnav: ");
    const userMenu = [
      // {
      //   label:'Thêm tỷ lệ hao hụt',
      //   icon:'pi pi-fw pi-plus-circle',
      //   url: '/drugcreatepage'
      // },
      // {
      //   label:'Thực hiện bào chế thuốc',
      //   icon:'pi pi-fw pi-sync',
      //   url: '/detailpage'
      // },
      // {
      //   label:'Thống kê ds thuốc bào chế',
      //   icon:'pi pi-fw pi-list',
      //   url: '/statisticalpage'
      // }

      // {
      //   label:'Tiếp nhận thiết bị',
      //   icon:'pi pi-fw pi-plus-circle',
      //   url: '/inputDevice'
      // },
      {
        label:'Tình trạng thiết bị ',
        icon:'pi pi-fw pi-briefcase',
        url: '/managerDevicePage',
        badge: 2
      },
      {
        label:'Quản lý toàn bộ thiết bị',
        icon:'pi pi-fw pi-list',
        url: '/allDevicePage'
      },
    ];
    const adminMenu = [
      {
        label:'Tiếp nhận thiết bị',
        icon:'pi pi-fw pi-plus-circle',
        url: '/inputDevice'
      },
      {
        label:'Tình trạng thiết bị',
        icon:'pi pi-fw pi-briefcase',
        url: '/managerDevicePage',
        badge: 2
      },
      {
        label:'Quản lý toàn bộ thiết bị',
        icon:'pi pi-fw pi-list',
        url: '/allDevicePage'
      },
      {
        label:'Xuất báo cáo',
        icon:'pi pi-fw pi-file-export',
        url: '/reportPage'
      },
      {
        label:'Danh sách tài khoản',
        icon:'pi pi-fw pi-users',
        url: '/userlist'
      },
      {
        label:'Đăng ký tài khoản mới',
        icon:'pi pi-fw pi-user-plus',
        url: '/registerpage'
      }
    ];
    const appMenus = computed(() => {
      // const loadThietBiChuyenGiao = () => {
      //   QLTSRepository.getListThietBiChuyenGiao()
      //       .then((response) => {
      //         listThietBiChuyenGiao.value = response.data;
      //       })
      //       .catch(err => {
      //         toast.add({
      //           severity: 'error',
      //           summary: 'Lỗi',
      //           detail:err.response.data,
      //           life: 2000
      //         });
      //       });
      // }

      // if(props.isLoggedIn)
      // loadThietBiChuyenGiao();
      if(store.state.permission == "admin") return adminMenu;
      else if(store.state.permission == "user") return userMenu;
      else return defaultMenu;
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      store.dispatch('clearFullName');
      store.dispatch('clearTeam');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenus,
      logout,
      listThietBiChuyenGiao
    }
  }
}
